<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>บัญชี/หมวดหมู่ ที่ใช้บ่อย</ion-title>
        <ion-buttons slot="start">
          <ion-button :router-link="{ name: 'settings' }" router-direction="back">
            <ion-icon :ios="icons.backOutline" :md="icons.backSharp"></ion-icon>
            <ion-label>Back</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-list lines="none">
        <ion-list-header>
          <p>ตั้งค่า บัญชี/หมวดหมู่ ที่ใช้บ่อย</p>
        </ion-list-header>
        <ion-item>
          <ion-text class="small">
            กดแต่ละปุ่ม ด้านล่างนี้เพื่อเข้าไปตั้งค่า
          </ion-text>
        </ion-item>
        <ion-item
          v-for="item in modes"
          :key="item.mode"
          button
          :router-link="{
            name: 'settings-favoriteAccountsPicker',
            params: { mode: item.mode },
          }"
        >
          <ion-label :color="item.color">{{ item.label }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-text class="small" color="medium">
            <ion-icon size="small" :ios="icons.infoOutline" :md="icons.infoSharp"></ion-icon>
            หากไม่ตั้งค่าตรงนี้ เวลาบันทึกข้อมูล แอพจะแสดงรายชื่อบัญชี และหมวดหมู่ โดยอ้างอิงจากสถิติการใช้งานที่ผ่านมา โดยอัตโนมัติ
          </ion-text>
        </ion-item>
      </ion-list>
    </ion-content>

  </ion-page>
</template>

<script>
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { chevronBackOutline, chevronBackSharp, informationCircleOutline, informationCircleSharp } from "ionicons/icons";
import { inputModes } from "../../../helper.js";
export default {
  name: "Form",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
  },
  data() {
    return {};
  },
  computed: {
    icons() {
      return {
        backOutline: chevronBackOutline,
        backSharp: chevronBackSharp,
        infoOutline: informationCircleOutline,
        infoSharp: informationCircleSharp,
      };
    },
    modes() {
      return inputModes();
    },
  },
};
</script>

<style scoped>
ion-text.small {
  font-size: 80%;
}
</style>
